import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MaintenanceModule } from '@c/maintenance/maintenance.module';
import { ExpectedMoveModule } from '@c/shared/expected-move/expected-move.module';
import { WheelChartLegendComponent } from '@c/shared/wheel-chart-legend';
import { TopMenuModule } from '@c/top-menu/top-menu.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { InputMaskModule } from '@ngneat/input-mask';
import { AdminService } from '@s/admin.service';
import { DialogsService } from '@s/common/dialogs.service';
import { StreamingService } from '@s/streaming.service';
import { AngularSplitModule } from 'angular-split';
import { InViewportModule } from 'ng-in-viewport';
import { NgxEditorModule } from 'ngx-editor';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from 'ngx-spinner';

import { GroupedWatchlistComponent } from '@c/grouped-watchlist/grouped-watchlist.component';
import { IncomeStatementComponent } from '@c/income-statement/income-statement.component';
import { PortfolioComponent } from '@c/portfolio/portfolio.component';
import { FiltersPresetsMenuComponent } from '@c/shared/filter-presets-menu/filters-presets-menu.component';
import { LiveDataModule } from '@c/shared/live-data/live-data.module';
import { ScannerSymbolsListComponent } from '@c/shared/scanner-symbols-list/scanner-symbols-list.component';
import { SymbolDetailsPanelModule } from '@c/shared/symbol-details-panel/symbol-details-panel.module';
import { SymbolFlagModule } from '@c/shared/symbol-flag/symbol-flag.module';
import { StrategySkeletonComponent } from '@c/strategy-skeleton/strategy-skeleton.component';
import { AddTradingLogTradeComponent } from '@c/trading-log/shared/add-trading-log-trade';
import { TradingLogSymbolSummaryPanelComponent } from '@c/trading-log/shared/trading-log-symbol-summary-panel';
import { TradingPanelOrderPanelComponent } from '@c/trading-panel-order-panel/trading-panel-order-panel.component';
import { EditionsSharedModule } from '@containers/editions-demo/editions-shared.module';
import { AlphabetOnlyDirective } from '@core1/directives/alphabet-only.directive';
import { CommaSeperatorPipe } from '@core1/directives/comma-seperator.directive';
import { DotSupportDirective } from '@core1/directives/dot-support.directive';
import { InputFormatterDirective } from '@core1/directives/input-formatter.directive';
import { IntegerDirective } from '@core1/directives/integer-only.directive';
import { FocusedDirective } from '@core1/directives/key-up-down';
import { MomentPipe } from '@core1/directives/momentPipe';
import { NumberDirective } from '@core1/directives/number-only.directive';
import { HeaderModule } from '@m/common/header/header.module';
import { SharedModule } from '@sh/shared.module';
import { EditAnnouncementPopupComponent } from './common/admin-config/edit-announcement-popup/edit-announcement-popup.component';
import { ConfirmModalComponent } from './common/confirm-modal/confirm-modal.component';
import { DatePopupComponent } from './common/date-popup/date-popup.component';
import { PositionSizingPopupComponent } from './common/position-sizing-popup/position-sizing-popup.component';
import { WheelHeaderPopupComponent } from './common/wheel-header-popup/wheel-header-popup.component';
import { MainRoutingModule } from './main-routing.module';
import { MainComponent } from './main.component';
import { MaxPopupComponent } from './wheel/wheel-calculator/max-popup/max-popup.component';
import { PrintWheelPopupComponent } from './wheel/wheel-calculator/print-wheel-popup/print-wheel-popup.component';
import { WheelCalculatorComponent } from './wheel/wheel-calculator/wheel-calculator.component';
import { WheelCalculatorService } from './wheel/wheel-calculator/wheel-calculator.service';
import { WheelChartComponent } from './wheel/wheel-chart/wheel-chart.component';
import { WheelDatawindowComponent } from './wheel/wheel-data-window/wheel-datawindow.component';
import { WheelScannerFilterComponent } from './wheel/wheel-scanner-filter/wheel-scanner-filter.component';
import { WheelScannerComponent } from './wheel/wheel-scanner-tab/wheel-scanner.component';
import { WheelTimerComponent } from './wheel/wheel-timer/wheel-timer.component';
import { WheelWatchlistComponent } from './wheel/wheel-watchlist/wheel-watchlist.component';
import { WheelComponent } from './wheel/wheel.component';
import { WheelService } from './wheel/wheel.service';

@NgModule({
  declarations: [
    MainComponent,
    ConfirmModalComponent,
    WheelComponent,
    WheelChartComponent,
    WheelCalculatorComponent,
    WheelScannerComponent,
    WheelDatawindowComponent,
    PositionSizingPopupComponent,
    WheelHeaderPopupComponent,
    MaxPopupComponent,
    MaxPopupComponent,
    MomentPipe,
    NumberDirective,
    IntegerDirective,
    InputFormatterDirective,
    AlphabetOnlyDirective,
    DotSupportDirective,
    PrintWheelPopupComponent,
    EditAnnouncementPopupComponent,
    DatePopupComponent,
    FocusedDirective,
    WheelScannerFilterComponent,
    WheelTimerComponent,
    WheelWatchlistComponent,
  ],
  exports: [],
  imports: [
    InViewportModule,
    HeaderModule,
    SharedModule,
    NgxEditorModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MainRoutingModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    OverlayModule,
    PortalModule,
    ScrollingModule,
    AngularSplitModule,
    NgxSpinnerModule,
    MatTooltipModule,
    InputMaskModule,
    AngularEditorModule,
    NgxPaginationModule,
    TopMenuModule,
    MaintenanceModule,
    ExpectedMoveModule,
    TradingLogSymbolSummaryPanelComponent,
    SymbolDetailsPanelModule,
    SymbolFlagModule,
    WheelChartLegendComponent,
    EditionsSharedModule,
    TradingPanelOrderPanelComponent,
    PortfolioComponent,
    IncomeStatementComponent,
    FiltersPresetsMenuComponent,
    GroupedWatchlistComponent,
    ScannerSymbolsListComponent,
    AddTradingLogTradeComponent,
    NgxMaskDirective,
    NgxMaskPipe,
    LiveDataModule,
    StrategySkeletonComponent,
  ],
  providers: [
    WheelCalculatorService,
    UpperCasePipe,
    CommaSeperatorPipe,
    WheelService,
    DialogsService,
    StreamingService,
    AdminService,
    provideNgxMask(),
  ],
})
export class MainModule {}
