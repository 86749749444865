<div class="wheel-filter wheel-scanner-filter" [ngClass]="{ 'hide': hideFilter }">
  <div class="title-block" (click)="$event.stopPropagation(); toggleFilter()">
    <div class="title-container">
      <button
        mat-icon-button
        class="close-open-item-btn component-btn"
        (click)="$event.stopPropagation(); toggleFilter()"
      >
        <span class="arrow-wrapper" [ngClass]="{ 'close-item': !this.hideFilter }">
          <div class="close-open-arrow"></div>
        </span>
      </button>

      <div class="title">
        <mat-icon [svgIcon]="'filter-icon'" class="l-icon"></mat-icon>
        <span>Filters</span>
        <div class="filters-count" *ngIf="!filterCount"></div>
      </div>
    </div>

    <div class="wheel-filters-presets-menu-container">
      <app-filters-presets-menu
        class="wheel-filters-presets-menu"
        [selectedPresetId]="selectedFiltersPresetId"
        [predefinedFiltersList]="wheelFiltersPredefinedPresets"
        [customFiltersList]="wheelFiltersCustomPresets"
        (predefinedPresetsUpdated)="onPredefinedPresetsUpdated($event)"
        (customPresetsUpdated)="onCustomPresetsUpdated($event)"
        (presetSelected)="onSelectPreset($event)"
        (presetSettingsSaved)="onSavePresetSettings($event)"
      ></app-filters-presets-menu>
    </div>

    <div class="actions" *ngIf="!filterCount">
      <button
        mat-button
        class="refresh"
        *ngIf="!filterCount"
        (click)="$event.stopPropagation(); resetFilters()"
      >
        <mat-icon [svgIcon]="'reset'" class="xl-icon"></mat-icon>
        Reset
      </button>
      <!--  button is temporarily hidden, "default" settings can be set in predefined preset  -->
      <!-- <button
        mat-button class="border-btn recommended-btn"
        (click)="$event.stopPropagation(); resetToRecommendedFilters()"
        [matTooltip]="'Set the scanner to the default settings that Markus and Mark use'"
        [matTooltipPosition]="'below'"
        [matTooltipHideDelay]="0"
      >
        Default
      </button> -->
    </div>
  </div>

  <ul class="filter-input-data-duplicate" (click)="toggleFilter()">
    <li>
      Flag: <span class="duplicate-data">{{ flagsDuplicate.join(', ') }}</span>
    </li>
    <li>
      Min Strike: <span class="duplicate-data">{{ minStrikeReadableValues[wheelFilter.minStrike] }}</span>
    </li>
    <li>
      Dividends: <span class="duplicate-data">{{ dividends.value | titlecase }}</span>
    </li>
    <li *ngIf="marketCapFrom.value || marketCapTo.value">
      Market Cap<span *ngIf="marketCapFrom.value && marketCapTo.value">:</span>
      <span class="duplicate-data">{{ displayedFilterValues.marketCap }}</span>
    </li>
    <li *ngIf="peRatioFrom.value || peRatioTo.value">
      P/E Ratio<span *ngIf="peRatioFrom.value && peRatioTo.value">:</span>
      <span class="duplicate-data">{{ displayedFilterValues.PERatio }}</span>
    </li>
    <li *ngIf="changeInPercentFrom.value || changeInPercentTo.value">
      Change in %<span *ngIf="changeInPercentFrom.value && changeInPercentTo.value">:</span>
      <span class="duplicate-data">{{ displayedFilterValues.changeInPercent }}</span>
    </li>
    <li>
      Sectors: <span class="duplicate-data">{{ sectorsFilterVisibleValues[sectors.value] }}</span>
    </li>
    <li>
      Expiration:
      <span class="duplicate-data" *ngIf="expiration.value === 'All'; else expirationDate">{{ expiration.value }}</span>
      <ng-template #expirationDate><span class="duplicate-data">{{ expiration.value | date : 'MMM d, y' }}</span></ng-template>
    </li>
    <li *ngIf="strikeFrom.value || strikeTo.value">
      Strike<span *ngIf="strikeFrom.value && strikeTo.value">:</span>
      <span class="duplicate-data">{{ displayedFilterValues.strike }}</span>
    </li>
    <li *ngIf="roiFrom.value || roiTo.value">
      ROI %<span *ngIf="roiFrom.value && roiTo.value">:</span>
      <span class="duplicate-data">{{ displayedFilterValues.roi }}</span>
    </li>
    <li *ngIf="premiumFrom.value || premiumTo.value">
      Premium<span *ngIf="premiumFrom.value && premiumTo.value">:</span>
      <span class="duplicate-data">{{ displayedFilterValues.premium }}</span>
    </li>
    <li *ngIf="dropInPercentFrom.value || dropInPercentTo.value">
      Drop in %<span *ngIf="dropInPercentFrom.value && dropInPercentTo.value">:</span>
      <span class="duplicate-data">{{ displayedFilterValues.dropInPercent }}</span>
    </li>
    <!--  temporarily hidden, to uncomment after release 2.55  -->
    <!-- @if (earningsBeforeExp.value !== earningBeforeExpValues.Any) {
      <li>
        Earnings:
        <span class="duplicate-data">{{ earningBeforeExpReadableValues[earningsBeforeExp.value] }}</span>
      </li>
    } -->
  </ul>

  <div class="filters" (click)="$event.stopPropagation()">
    <div class="filters-group stock-group">
      <div class="input-item">
        <div class="form-group select-form flags form-label contain">
          <mat-form-field>
            <span class="top-label label-icon-container">Flag</span>
            <mat-select
              multiple
              [formControl]="flags"
              class="item-select filters-select flag-select"
              [ngStyle]="{ '--filters-select-width': + (flags.value.length * 12) + 'px' }"
              panelClass="filter-select-panel"
              (selectionChange)="flagSelect(); onFilterChange()"
              (focusout)="focusOutEvent($event)"
              (focus)="focusEvent($event)"
              (click)="$event.stopPropagation()"
            >
              <mat-select-trigger class="flag-select-trigger">
                <span *ngFor="let item of flags.value" [class]="item || 'none'">
                  <img [src]="flagImage(item)" [alt]="item" *ngIf="item !== 'All'; else noImage" />
                  <ng-template #noImage>
                    <span>All</span>
                  </ng-template>
                </span>
              </mat-select-trigger>
              <mat-option *ngFor="let flag of flagList" [value]="flag.value" class="filters-option">
                <img *ngIf="flag.img !== null" [src]="flag.img" [alt]="flag.name"/>
                {{ flag.name }}
              </mat-option>
            </mat-select>
            <span class="top-label-result">
              <span class="flags-result top-label">
                <span *ngIf="flags.value.length === 0">
                  All
                </span>
              </span>
              <mat-icon [svgIcon]="'arrow-down_icon'" class="small-icon"></mat-icon>
            </span>
          </mat-form-field>
        </div>
      </div>

      <div class="input-item">
        <div class="form-group select-form form-label contain">
          <mat-form-field>
            <span class="top-label label-icon-container" mat-select-trigger>
              <span class="title">
                <span
                  class="underline"
                  [matTooltip]="minStrikeFilterHint"
                  [matTooltipPosition]="tooltipPosition"
                  [matTooltipHideDelay]="0"
                >Min</span> Strike
              </span>
              <span class="top-label-result">
                <span class="summary">
                  {{ minStrikeReadableValues[wheelFilter.minStrike] }}
                </span>
                <mat-icon [svgIcon]="'arrow-down_icon'" class="small-icon"></mat-icon>
              </span>
            </span>
            <mat-select
              [formControl]="minStrike"
              class="item-select"
              (selectionChange)="onFilterChange()"
              (focusout)="focusOutEvent($event)"
              (focus)="focusEvent($event)"
              (click)="$event.stopPropagation()"
            >
              <mat-option [value]="minStrikeFilterValues.AtLowestCloseInd">At Lowest Ind.</mat-option>
              <mat-option [value]="minStrikeFilterValues.Below">Below</mat-option>
              <mat-option [value]="minStrikeFilterValues.AtOrBellowLowestInd">At or Below Lowest Ind.</mat-option>
              <mat-option [value]="minStrikeFilterValues.AllStrikes">All</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="input-item">
        <div class="form-group select-form form-label contain">
          <mat-form-field>
            <span class="top-label label-icon-container">
              <span class="title">
                <span
                  class="underline"
                  [matTooltip]="dividendsFilterHint"
                  [matTooltipPosition]="'right'"
                  [matTooltipHideDelay]="0"
                >Div</span>idends
              </span>
              <span class="top-label-result">
                <span class="summary">
                  {{ dividends.value | titlecase }}
                </span>
                <mat-icon [svgIcon]="'arrow-down_icon'" class="small-icon"></mat-icon>
              </span>
            </span>
            <mat-select
              [formControl]="dividends"
              class="item-select"
              (selectionChange)="onFilterChange()"
              (focusout)="focusOutEvent($event)"
              (focus)="focusEvent($event)"
              (click)="$event.stopPropagation()"
            >
              <mat-option [value]="dividendsCount.All">All</mat-option>
              <mat-option [value]="dividendsCount.AtLeastOne">Yes</mat-option>
              <mat-option [value]="dividendsCount.None">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="input-item">
        <div class="form-group form-label contain">
          <!-- fix: use touchend and mouseup for IPad - it have an issue with select/focus -->
          <span
            [mat-menu-trigger-for]="marketCapModal"
            class="top-label label-icon-container"
            (menuOpened)="marketCapFromRef.select(); marketCapFromRef.focus()"
            (touchend)="marketCapFromRef.select(); marketCapFromRef.focus()"
            (mouseup)="marketCapFromRef.select(); marketCapFromRef.focus()"
          >
            <span class="title">
              <span
                class="underline"
                [matTooltip]="marketCapFilterHint"
                [matTooltipPosition]="tooltipPosition"
                [matTooltipHideDelay]="0"
              >Mar</span>ket Cap.
            </span>
            <span class="top-label-result">
              <span class="summary">
                {{ displayedFilterValues.marketCap }}
              </span>
              <mat-icon [svgIcon]="'arrow-down_icon'" class="small-icon"></mat-icon>
            </span>
          </span>

          <mat-menu #marketCapModal="matMenu" class="wheel-scanner-mat-menu">
            <div class="menu-content" (click)="$event.stopPropagation(); $event.preventDefault();">
              <div class="wheel-scanner-mat-menu-heading">
                <span class="title">Market Cap.</span>
              </div>
              <div class="fields-wrapper">
                <mat-form-field floatLabel="always" appearance="outline" subscriptSizing="dynamic">
                  <mat-label>Greater than</mat-label>
                  <input
                    #marketCapFromRef
                    [id]="textInputKeys.MarketCapFrom"
                    [inputMask]="marketCapInputMask"
                    autocomplete="off"
                    type="text"
                    maxlength="19"
                    matInput
                    spellcheck="false"
                    [formControl]="marketCapFrom"
                    [ngClass]="{ 'wheel-input': true, 'invalid-input': !isMarketCapFromValid }"
                    (input)="formatMarketCapValue(true)"
                    (keyup)="onFilterChange($event, textInputKeys.MarketCapFrom)"
                    (keydown.enter)="$event.stopPropagation(); $event.preventDefault(); marketCapToRef.select(); marketCapToRef.focus()"
                    (keydown.tab)="$event.stopPropagation(); $event.preventDefault(); marketCapToRef.select(); marketCapToRef.focus()"
                    (keydown.escape)="focusOutEvent($event, textInputKeys.MarketCapFrom)"
                    (focusout)="focusOutEvent($event, textInputKeys.MarketCapFrom)"
                    (focus)="focusEvent($event); selectInputValue($event)"
                  />
                </mat-form-field>
                <div class="fields-separator"></div>
                <mat-form-field floatLabel="always" appearance="outline" subscriptSizing="dynamic">
                  <mat-label>Less than</mat-label>
                  <input
                    #marketCapToRef
                    [id]="textInputKeys.MarketCapTo"
                    [inputMask]="marketCapInputMask"
                    autocomplete="off"
                    type="text"
                    maxlength="19"
                    matInput
                    spellcheck="false"
                    [formControl]="marketCapTo"
                    [ngClass]="{ 'wheel-input': true, 'invalid-input': !isMarketCapToValid }"
                    (input)="formatMarketCapValue(false)"
                    (keyup)="onFilterChange($event, textInputKeys.MarketCapTo)"
                    (keydown.tab)="$event.stopPropagation(); $event.preventDefault(); marketCapFromRef.select(); marketCapFromRef.focus()"
                    (keydown.enter)="$event.stopPropagation(); $event.preventDefault(); marketCapFromRef.select(); marketCapModal.closed.emit()"
                    (keydown.escape)="marketCapFromRef.select(); focusOutEvent($event, textInputKeys.MarketCapTo)"
                    (focusout)="focusOutEvent($event, textInputKeys.MarketCapTo)"
                    (focus)="focusEvent($event); selectInputValue($event)"
                  />
                </mat-form-field>
              </div>
            </div>
          </mat-menu>
        </div>
      </div>

      <div class="input-item">
        <div class="form-group form-label contain">
          <span
            [mat-menu-trigger-for]="peRatioModal"
            class="top-label label-icon-container"
            (menuOpened)="peRatioFromRef.select()"
          >
            <span class="title">
              <span
                class="underline"
                [matTooltip]="PERatioFilterHint"
                [matTooltipPosition]="'below'"
                [matTooltipHideDelay]="0"
              >P/E</span> Ratio
            </span>
            <span class="top-label-result">
              <span class="summary">
                {{ displayedFilterValues.PERatio }}
              </span>
              <mat-icon [svgIcon]="'arrow-down_icon'" class="small-icon"></mat-icon>
            </span>
          </span>
          <mat-menu #peRatioModal="matMenu" class="wheel-scanner-mat-menu">
            <div class="menu-content" (click)="$event.stopPropagation(); $event.preventDefault();">
              <div class="wheel-scanner-mat-menu-heading">
                <span class="title">P/E Ratio</span>
              </div>
              <div class="fields-wrapper">
                <mat-form-field floatLabel="always" appearance="outline" subscriptSizing="dynamic">
                  <mat-label>Greater than</mat-label>
                  <input
                    #peRatioFromRef
                    [id]="textInputKeys.PERatioFrom"
                    [inputMask]="strikePriceInputMask"
                    autocomplete="off"
                    matInput
                    min="0"
                    maxlength="10"
                    [formControl]="peRatioFrom"
                    type="text"
                    class="wheel-input"
                    (keyup)="onFilterChange($event, textInputKeys.PERatioFrom)"
                    (keydown.enter)="$event.stopPropagation(); $event.preventDefault(); peRatioToRef.select()"
                    (keydown.tab)="$event.stopPropagation(); $event.preventDefault(); peRatioToRef.select()"
                    (keydown.escape)="focusOutEvent($event, textInputKeys.PERatioFrom)"
                    (focusout)="focusOutEvent($event, textInputKeys.PERatioFrom)"
                    (focus)="focusEvent($event); selectInputValue($event)"
                  />
                </mat-form-field>
                <div class="fields-separator"></div>
                <mat-form-field floatLabel="always" appearance="outline" subscriptSizing="dynamic">
                  <mat-label>Less than</mat-label>
                  <input
                    #peRatioToRef
                    [id]="textInputKeys.PERatioTo"
                    [inputMask]="strikePriceInputMask"
                    autocomplete="off"
                    matInput
                    min="0"
                    maxlength="10"
                    [formControl]="peRatioTo"
                    type="text"
                    class="wheel-input"
                    (keyup)="onFilterChange($event, textInputKeys.PERatioTo)"
                    (keydown.tab)="$event.stopPropagation(); $event.preventDefault(); peRatioFromRef.select()"
                    (keydown.enter)="$event.stopPropagation(); $event.preventDefault(); peRatioFromRef.select(); peRatioModal.closed.emit()"
                    (keydown.escape)="peRatioFromRef.select(); focusOutEvent($event, textInputKeys.PERatioTo)"
                    (focusout)="focusOutEvent($event, textInputKeys.PERatioTo)"
                    (focus)="focusEvent($event); selectInputValue($event)"
                  />
                </mat-form-field>
              </div>
            </div>
          </mat-menu>
        </div>
      </div>

      <div class="input-item">
        <div class="form-group form-label contain input-hint">
          <span
            class="top-label label-icon-container"
            [matMenuTriggerFor]="changeInPercentModal"
            (menuOpened)="changeInPercentModalFromRef.select()"
          >
            <span class="title">
              <span
                class="underline"
                [matTooltip]="changeInFilterHint"
                [matTooltipPosition]="'below'"
                [matTooltipHideDelay]="0"
              >Cha</span>nge in %
            </span>
            <span class="top-label-result">
              <span class="summary">
                {{ displayedFilterValues.changeInPercent }}
              </span>
              <mat-icon [svgIcon]="'arrow-down_icon'" class="small-icon"></mat-icon>
            </span>
          </span>
          <mat-menu #changeInPercentModal="matMenu" class="wheel-scanner-mat-menu">
            <div class="menu-content" (click)="$event.stopPropagation(); $event.preventDefault();">
              <div class="wheel-scanner-mat-menu-heading">
                <span class="title">Change in %</span>
              </div>
              <div class="fields-wrapper">
                <mat-form-field floatLabel="always" appearance="outline" subscriptSizing="dynamic">
                  <mat-label>Greater than</mat-label>
                  <input
                    #changeInPercentModalFromRef
                    [id]="textInputKeys.ChangeInPercentFrom"
                    [inputMask]="changeInInputMask"
                    maxlength="7"
                    autocomplete="off"
                    matInput
                    [formControl]="changeInPercentFrom"
                    class="wheel-input"
                    (keyup)="onFilterChange($event, textInputKeys.ChangeInPercentFrom)"
                    (keydown.enter)="$event.stopPropagation(); $event.preventDefault(); changeInPercentModalToRef.select()"
                    (keydown.tab)="$event.stopPropagation(); $event.preventDefault(); changeInPercentModalToRef.select()"
                    (keydown.escape)="focusOutEvent($event, textInputKeys.ChangeInPercentFrom)"
                    (focusout)="focusOutEvent($event, textInputKeys.ChangeInPercentFrom)"
                    (focus)="focusEvent($event); selectInputValue($event)"
                  />
                </mat-form-field>
                <div class="fields-separator"></div>
                <mat-form-field floatLabel="always" appearance="outline" subscriptSizing="dynamic">
                  <mat-label>Less than</mat-label>
                  <input
                    #changeInPercentModalToRef
                    [id]="textInputKeys.ChangeInPercentTo"
                    [inputMask]="changeInInputMask"
                    maxlength="7"
                    autocomplete="off"
                    matInput
                    [formControl]="changeInPercentTo"
                    class="wheel-input"
                    (keyup)="onFilterChange($event, textInputKeys.ChangeInPercentTo)"
                    (keydown.tab)="$event.stopPropagation(); $event.preventDefault(); changeInPercentModalFromRef.select()"
                    (keydown.enter)="$event.stopPropagation(); $event.preventDefault(); changeInPercentModalFromRef.select(); changeInPercentModal.closed.emit()"
                    (keydown.escape)="changeInPercentModalFromRef.select(); focusOutEvent($event, textInputKeys.ChangeInPercentTo)"
                    (focusout)="focusOutEvent($event, textInputKeys.ChangeInPercentTo)"
                    (focus)="focusEvent($event); selectInputValue($event)"
                  />
                </mat-form-field>
              </div>
            </div>
          </mat-menu>
        </div>
      </div>

      <ng-container *appHideForUnavailableFeature="features.TradingLog">
        <div class="input-item">
          <div class="form-group select-form form-label contain">
            <mat-form-field>
              <span class="top-label label-icon-container">
                <span class="title">
                  Sectors
                </span>
                <span class="top-label-result">
                  <span class="summary">
                    {{ sectorsFilterVisibleValues[sectors.value] }}
                  </span>
                  <mat-icon [svgIcon]="'arrow-down_icon'" class="small-icon"></mat-icon>
                </span>
              </span>
              <mat-select
                [formControl]="sectors"
                class="item-select"
                (selectionChange)="onFilterChange()"
                (focusout)="focusOutEvent($event)"
                (focus)="focusEvent($event)"
                (click)="$event.stopPropagation()"
              >
                <mat-option [value]="sectorsValues.All">
                  {{ sectorsFilterVisibleValues[sectorsValues.All] }}
                </mat-option>
                <mat-option [value]="sectorsValues.ExcludePortfolioSectors">
                  {{ sectorsFilterVisibleValues[sectorsValues.ExcludePortfolioSectors] }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="empty-input-item"></div>
        <div class="empty-input-item"></div>
      </ng-container>
    </div>

    <div class="filters-groups-separator"></div>

    <div class="filters-group options-group">
      <div class="input-item">
        <div class="form-group select-form form-label contain">
          <mat-form-field>
            <span class="top-label label-icon-container">
              Expiration
              <span class="top-label-result">
                <span class="summary" *ngIf="expiration.value === 'All'">
                  {{ expiration.value }}
                </span>
                <span class="summary" *ngIf="expiration.value !== 'All'">
                  {{ expiration.value | date : 'MMM d, y' }}
                </span>
                <mat-icon [svgIcon]="'arrow-down_icon'" class="small-icon"></mat-icon>
              </span>
            </span>
            <mat-select
              [formControl]="expiration"
              class="item-select"
              (selectionChange)="flagSelect(); onFilterChange()"
              (focusout)="focusOutEvent($event)"
              (focus)="focusEvent($event)"
              (click)="$event.stopPropagation()"
            >
              <mat-option value="All">All</mat-option>
              <mat-option *ngFor="let date of expirationDates" [value]="date" class="expiration-option">
                {{ date | date: "MMM d, y" }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="input-item">
        <div class="form-group form-label contain input-hint">
          <span
            class="top-label label-icon-container"
            [matMenuTriggerFor]="strikeModal"
            (menuOpened)="strikeFromRef.select()"
          >
            Strike
            <span class="top-label-result">
              <span class="summary">
                {{ displayedFilterValues.strike }}
              </span>
              <mat-icon [svgIcon]="'arrow-down_icon'" class="small-icon"></mat-icon>
            </span>
          </span>
          <mat-menu #strikeModal="matMenu" class="wheel-scanner-mat-menu">
            <div class="menu-content" (click)="$event.stopPropagation(); $event.preventDefault();">
              <div class="wheel-scanner-mat-menu-heading">
                <span class="title">Strike</span>
              </div>
              <div class="fields-wrapper">
                <mat-form-field floatLabel="always" appearance="outline" subscriptSizing="dynamic">
                  <mat-label>Greater than</mat-label>
                  <input
                    #strikeFromRef
                    [id]="textInputKeys.StrikeFrom"
                    [inputMask]="strikePriceInputMask"
                    min="0"
                    maxlength="10"
                    autocomplete="off"
                    matInput
                    [formControl]="strikeFrom"
                    class="wheel-input"
                    (keyup)="onFilterChange($event, textInputKeys.StrikeFrom)"
                    (keydown.enter)="$event.stopPropagation(); $event.preventDefault(); strikeToRef.select()"
                    (keydown.tab)="$event.stopPropagation(); $event.preventDefault(); strikeToRef.select()"
                    (keydown.escape)="focusOutEvent($event, textInputKeys.StrikeFrom)"
                    (focusout)="focusOutEvent($event, textInputKeys.StrikeFrom)"
                    (focus)="focusEvent($event); selectInputValue($event)"
                  />
                </mat-form-field>
                <div class="fields-separator"></div>
                <mat-form-field floatLabel="always" appearance="outline" subscriptSizing="dynamic">
                  <mat-label>Less than</mat-label>
                  <input
                    #strikeToRef
                    [id]="textInputKeys.StrikeTo"
                    [inputMask]="strikePriceInputMask"
                    min="0"
                    maxlength="10"
                    autocomplete="off"
                    matInput
                    [formControl]="strikeTo"
                    class="wheel-input"
                    (keyup)="onFilterChange($event, textInputKeys.StrikeTo)"
                    (keydown.tab)="$event.stopPropagation(); $event.preventDefault(); strikeFromRef.select()"
                    (keydown.enter)="$event.stopPropagation(); $event.preventDefault(); strikeFromRef.select(); strikeModal.closed.emit()"
                    (keydown.escape)="strikeFromRef.select(); focusOutEvent($event, textInputKeys.StrikeTo)"
                    (focusout)="focusOutEvent($event, textInputKeys.StrikeTo)"
                    (focus)="focusEvent($event); selectInputValue($event)"
                  />
                </mat-form-field>
              </div>
            </div>
          </mat-menu>
        </div>
      </div>

      <div class="input-item roi-item">
        <div class="form-group form-label contain input-hint">
          <span
            class="top-label label-icon-container"
            [matMenuTriggerFor]="roiModal"
            (menuOpened)="roiFromRef.select()"
          >
            ROI %
            <span class="top-label-result">
              <span class="summary">
                {{ displayedFilterValues.roi }}
              </span>
              <mat-icon [svgIcon]="'arrow-down_icon'" class="small-icon"></mat-icon>
            </span>
          </span>
          <mat-menu #roiModal="matMenu" class="wheel-scanner-mat-menu">
            <div class="menu-content" (click)="$event.stopPropagation(); $event.preventDefault();">
              <div class="wheel-scanner-mat-menu-heading">
                <span class="title">ROI</span>
              </div>
              <div class="fields-wrapper">
                <mat-form-field floatLabel="always" appearance="outline" subscriptSizing="dynamic">
                  <mat-label>Greater than</mat-label>
                  <input
                    #roiFromRef
                    [id]="textInputKeys.RoiFrom"
                    [inputMask]="strikePriceInputMask"
                    min="0"
                    maxlength="10"
                    autocomplete="off"
                    matInput
                    [formControl]="roiFrom"
                    class="wheel-input"
                    (keyup)="onFilterChange($event, textInputKeys.RoiFrom)"
                    (keydown.enter)="$event.stopPropagation(); $event.preventDefault(); roiToRef.select()"
                    (keydown.tab)="$event.stopPropagation(); $event.preventDefault(); roiToRef.select()"
                    (keydown.escape)="focusOutEvent($event, textInputKeys.RoiFrom)"
                    (focusout)="focusOutEvent($event, textInputKeys.RoiFrom)"
                    (focus)="focusEvent($event); selectInputValue($event)"
                  />
                  <mat-hint [align]="'start'">Min: 5</mat-hint>
                </mat-form-field>
                <div class="fields-separator"></div>
                <mat-form-field floatLabel="always" appearance="outline" subscriptSizing="dynamic">
                  <mat-label>Less than</mat-label>
                  <input
                    #roiToRef
                    [id]="textInputKeys.RoiTo"
                    [inputMask]="strikePriceInputMask"
                    min="0"
                    maxlength="10"
                    autocomplete="off"
                    matInput
                    [formControl]="roiTo"
                    class="wheel-input"
                    (keyup)="onFilterChange($event, textInputKeys.RoiTo)"
                    (keydown.tab)="$event.stopPropagation(); $event.preventDefault(); roiFromRef.select()"
                    (keydown.enter)="$event.stopPropagation(); $event.preventDefault(); roiFromRef.select(); roiModal.closed.emit()"
                    (keydown.escape)="roiFromRef.select(); focusOutEvent($event, textInputKeys.RoiTo)"
                    (focusout)="focusOutEvent($event, textInputKeys.RoiTo)"
                    (focus)="focusEvent($event); selectInputValue($event)"
                  />
                  <mat-hint [align]="'start'"></mat-hint>
                </mat-form-field>
              </div>
            </div>
          </mat-menu>
        </div>
      </div>

      <div class="input-item">
        <div class="form-group form-label contain">
          <span
            class="top-label label-icon-container"
            [matMenuTriggerFor]="premiumModal"
            (menuOpened)="premiumFromRef.select()"
          >
            Premium
            <span class="top-label-result">
              <span class="summary">
                {{ displayedFilterValues.premium }}
              </span>
              <mat-icon [svgIcon]="'arrow-down_icon'" class="small-icon"></mat-icon>
            </span>
          </span>
          <mat-menu #premiumModal="matMenu" class="wheel-scanner-mat-menu">
            <div class="menu-content" (click)="$event.stopPropagation(); $event.preventDefault();">
              <div class="wheel-scanner-mat-menu-heading">
                <span class="title">Premium</span>
              </div>
              <div class="fields-wrapper">
                <mat-form-field floatLabel="always" appearance="outline" subscriptSizing="dynamic">
                  <mat-label>Greater than</mat-label>
                  <input
                    #premiumFromRef
                    [id]="textInputKeys.PremiumFrom"
                    [inputMask]="strikePriceInputMask"
                    min="0"
                    maxlength="5"
                    autocomplete="off"
                    matInput
                    [formControl]="premiumFrom"
                    class="wheel-input"
                    (keyup)="onFilterChange($event, textInputKeys.PremiumFrom)"
                    (keydown.enter)="$event.stopPropagation(); $event.preventDefault(); premiumToRef.select()"
                    (keydown.tab)="$event.stopPropagation(); $event.preventDefault(); premiumToRef.select()"
                    (keydown.escape)="focusOutEvent($event, textInputKeys.PremiumFrom)"
                    (focusout)="focusOutEvent($event, textInputKeys.PremiumFrom)"
                    (focus)="focusEvent($event); selectInputValue($event)"
                  />
                </mat-form-field>
                <div class="fields-separator"></div>
                <mat-form-field floatLabel="always" appearance="outline" subscriptSizing="dynamic">
                  <mat-label>Less than</mat-label>
                  <input
                    #premiumToRef
                    [id]="textInputKeys.PremiumTo"
                    [inputMask]="strikePriceInputMask"
                    min="0"
                    maxlength="5"
                    autocomplete="off"
                    matInput
                    [formControl]="premiumTo"
                    class="wheel-input"
                    (keyup)="onFilterChange($event, textInputKeys.PremiumTo)"
                    (keydown.tab)="$event.stopPropagation(); $event.preventDefault(); premiumFromRef.select()"
                    (keydown.enter)="$event.stopPropagation(); $event.preventDefault(); premiumFromRef.select(); premiumModal.closed.emit()"
                    (keydown.escape)="premiumFromRef.select(); focusOutEvent($event, textInputKeys.PremiumTo)"
                    (focusout)="focusOutEvent($event, textInputKeys.PremiumTo)"
                    (focus)="focusEvent($event); selectInputValue($event)"
                  />
                </mat-form-field>
              </div>
            </div>
          </mat-menu>
        </div>
      </div>

      <div class="input-item">
        <div class="form-group form-label contain input-hint">
          <span
            class="top-label label-icon-container"
            [matMenuTriggerFor]="dropInPercentModal"
            (menuOpened)="dropInPercentModalFromRef.select()"
          >
            <span class="title">
              <span
                class="underline"
                [matTooltip]="'It specifies how much the stock can drop before it hits the strike price at which you could get assigned.'"
                [matTooltipPosition]="'below'"
                [matTooltipHideDelay]="0"
              >Drop</span> in %
            </span>
            <span class="top-label-result">
              <span class="summary">
                {{ displayedFilterValues.dropInPercent }}
              </span>
              <mat-icon [svgIcon]="'arrow-down_icon'" class="small-icon"></mat-icon>
            </span>
          </span>
          <mat-menu #dropInPercentModal="matMenu" class="wheel-scanner-mat-menu">
            <div class="menu-content" (click)="$event.stopPropagation(); $event.preventDefault();">
              <div class="wheel-scanner-mat-menu-heading">
                <span class="title">Drop in %</span>
              </div>
              <div class="fields-wrapper">
                <mat-form-field floatLabel="always" appearance="outline" subscriptSizing="dynamic">
                  <mat-label>Greater than</mat-label>
                  <input
                    #dropInPercentModalFromRef
                    [id]="textInputKeys.DropInPercentFrom"
                    [inputMask]="strikePriceInputMask"
                    min="0"
                    maxlength="6"
                    autocomplete="off"
                    matInput
                    [formControl]="dropInPercentFrom"
                    class="wheel-input"
                    (keyup)="onFilterChange($event, textInputKeys.DropInPercentFrom)"
                    (keydown.enter)="$event.stopPropagation(); $event.preventDefault(); dropInPercentModalToRef.select()"
                    (keydown.tab)="$event.stopPropagation(); $event.preventDefault(); dropInPercentModalToRef.select()"
                    (keydown.escape)="focusOutEvent($event, textInputKeys.DropInPercentFrom)"
                    (focusout)="focusOutEvent($event, textInputKeys.DropInPercentFrom)"
                    (focus)="focusEvent($event); selectInputValue($event)"
                  />
                </mat-form-field>
                <div class="fields-separator"></div>
                <mat-form-field floatLabel="always" appearance="outline" subscriptSizing="dynamic">
                  <mat-label>Less than</mat-label>
                  <input
                    #dropInPercentModalToRef
                    [id]="textInputKeys.DropInPercentTo"
                    [inputMask]="strikePriceInputMask"
                    min="0"
                    maxlength="6"
                    autocomplete="off"
                    matInput
                    [formControl]="dropInPercentTo"
                    class="wheel-input"
                    (keyup)="onFilterChange($event, textInputKeys.DropInPercentTo)"
                    (keydown.tab)="$event.stopPropagation(); $event.preventDefault(); dropInPercentModalFromRef.select()"
                    (keydown.enter)="$event.stopPropagation(); $event.preventDefault(); dropInPercentModalFromRef.select(); dropInPercentModal.closed.emit()"
                    (keydown.escape)="dropInPercentModalFromRef.select(); focusOutEvent($event, textInputKeys.DropInPercentTo)"
                    (focusout)="focusOutEvent($event, textInputKeys.DropInPercentTo)"
                    (focus)="focusEvent($event); selectInputValue($event)"
                  />
                </mat-form-field>
              </div>
            </div>
          </mat-menu>
        </div>
      </div>

      <!--  temporarily hidden, to uncomment after release 2.55  -->
      <!-- <div class="input-item">
        <div class="form-group select-form form-label contain">
          <mat-form-field>
            <span class="top-label label-icon-container" mat-select-trigger>
              <span class="title">
                <span
                  class="underline"
                  [matTooltip]="earningsBeforeExtFilterHint"
                  [matTooltipPosition]="tooltipPosition"
                  [matTooltipHideDelay]="0"
                >Ear</span>nings
              </span>
              <span class="top-label-result">
                <span class="summary">
                  {{ earningBeforeExpReadableValues[wheelFilter.earningBeforeExp] }}
                </span>
                <mat-icon [svgIcon]="'arrow-down_icon'" class="small-icon"></mat-icon>
              </span>
            </span>
            <mat-select
              [formControl]="earningsBeforeExp"
              class="item-select"
              (selectionChange)="onFilterChange()"
              (focusout)="focusOutEvent($event)"
              (focus)="focusEvent($event)"
              (click)="$event.stopPropagation()"
            >
              <mat-option [value]="earningBeforeExpValues.Any">
                {{ earningBeforeExpReadableValues[earningBeforeExpValues.Any] }}
              </mat-option>
              <mat-option [value]="earningBeforeExpValues.NotBeforeExp">
                {{ earningBeforeExpReadableValues[earningBeforeExpValues.NotBeforeExp] }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div> -->
    </div>
  </div>
</div>
